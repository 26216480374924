import React, {Component} from "react"
import style from "./ScreenTemplate.module.css"
import Quiz from "./ScreenComponents/Quiz";
import TitleComponent from "./ScreenComponents/TitleComponent";
import TextComponent from "./ScreenComponents/TextComponent";
import ImageComponent from "./ScreenComponents/ImageComponent";
import MaterialComponent from "./ScreenComponents/MaterialComponent";
import VideoComponent from "./ScreenComponents/VideoComponent";
import LottieComponent from "./ScreenComponents/LottieComponent";
import TimerComponent from "./ScreenComponents/TimerComponent";
import MascotteComponent from "./ScreenComponents/MascotteComponent";
import EmptyComponent from "./ScreenComponents/EmptyComponents";
import StarRainComponent from "./ScreenComponents/StarRainComponent";

class ScreenTemplate extends Component {

    constructor(props) {
        super(props);
        this.stepFocus = this.props.stepFocus
        this.fixedScreen = 0;
        this.fixedScreenRef = React.createRef();
    }

    changeStepFocus(newFocus) {
        this.stepFocus = newFocus
        this.forceUpdate()
    }

    calculateShowNext() {
        if(this.fixedScreen === 0) {
            this.props.setShowNext(true)
        } else {
            this.fixedScreenRef.calculateShowNext()
        }
    }

    async nextTrigger() {
        if(this.fixedScreen === 0) {
            return true;
        } else {
            return this.fixedScreenRef.nextTrigger()
        }
    }

    showItems() {
        let screen = [];

        for(let i = 1; i <= Object.keys(this.props.step["screen"]).length; i++){
            if(this.props.step["screen"][i]["type"] === "title"){
                screen.push(
                    <TitleComponent
                        size={this.props.step["screen"][i]["size"]}
                        title={this.props.step["content"][i]["title"]["content"]}
                    />
                )
            } else if (this.props.step["screen"][i]["type"] === "text") {
                screen.push(
                    <TextComponent
                        size={this.props.step["screen"][i]["size"]}
                        text={this.props.step["content"][i]["text"]["content"]}
                        align={this.props.step["content"][i]["text"]["align"]}
                        isFirst={i == 1}
                    />
                )
            } else if (this.props.step["screen"][i]["type"] === "timer") {
                screen.push(
                    <TimerComponent
                        size={this.props.step["screen"][i]["size"]}
                        duration={this.props.step["content"][i]["timer"]["duration"]}
                        nbStep={this.props.step["order"]}
                        stepFocus={this.stepFocus}
                        addTimer={this.props.addTimer}
                        setRemainingTime={this.props.setRemainingTime}
                    />
                )
            } else if (this.props.step["screen"][i]["type"] === "video") {
                screen.push(
                    <VideoComponent
                        size={this.props.step["screen"][i]["size"]}
                        ligneBrisee={"ligne_brisee_1.1.svg"}
                        broken={this.props.step["content"][i]["video"]["break"]}
                        forced={this.props.step["content"][i]["video"]["forced"]}
                        loop={this.props.step["content"][i]["video"]["loop"]}
                        autoPlay={this.props.step["content"][i]["video"]["autoPlay"]}
                        file={this.props.step["content"][i]["video"]["file"]}
                        nbStep={this.props.step["order"]}
                        focused={this.stepFocus === this.props.step["order"]}
                    />
                )
            } else if (this.props.step["screen"][i]["type"] === "image") {
                screen.push(
                    <ImageComponent
                        size={this.props.step["screen"][i]["size"]}
                        ligneBrisee={"ligne_brisee_1.1.svg"}
                        broken={this.props.step["content"][i]["image"]["break"]}
                        forced={this.props.step["content"][i]["image"]["forced"]}
                        file={this.props.step["content"][i]["image"]["file"]}
                        type={this.props.step["content"][i]["image"]["type"]}
                    />
                )
            } else if (this.props.step["screen"][i]["type"] === "mascotte") {
                screen.push(
                    <MascotteComponent
                        file={this.props.step["content"][i]["mascotte"]["mascotte"]["file"]}
                        type={this.props.step["content"][i]["mascotte"]["mascotte"]["type"]}
                        dialogue={this.props.step["content"][i]["mascotte"]["dialogue"]}
                        center={false}
                        orientation={this.props.step["content"][i]["mascotte"]["mascotte"]["orientation"]}
                        size={this.props.step["screen"][i]["size"]}
                        color={"black"}
                        nbStep={this.props.step["order"]}
                        height={this.props.step["screen"][i]["size"]*100/6 + "%"}
                    />
                )
            } else if(this.props.step["screen"][i]["type"] === "lottie"){
                screen.push(
                    <LottieComponent
                        size={this.props.step["screen"][i]["size"]}
                        file={this.props.step["content"][i]["lottie"]["file"]}
                        ligneBrisee={"ligne_brisee_1.1.svg"}
                        broken={this.props.step["content"][i]["lottie"]["break"]}
                        forced={this.props.step["content"][i]["lottie"]["forced"]}
                        nbStep={this.props.step["order"]}
                    />
                )
            } else if(this.props.step["screen"][i]["type"] === "empty") {
                screen.push(
                    <EmptyComponent size={this.props.step["screen"][i]["size"]}/>
                )
            }
        }

        return screen
    }



    render() {

        if(this.props.step["screen"]["1"]["type"] === "quiz"){
            this.fixedScreen = 1
            return (
                <div className={style.container} style={{borderTopLeftRadius: "40px", borderTopRightRadius: "40px", overflow: "hidden"}}>
                    <Quiz
                        ref={(node) => {this.fixedScreenRef = node}}
                        question={this.props.step["content"]["1"]["quiz"]["question"]}
                        goodAnswer={this.props.step["content"]["1"]["quiz"]["goodAnswer"]}
                        wrongAnswer1={this.props.step["content"]["1"]["quiz"]["wrongAnswer1"]}
                        wrongAnswer2={this.props.step["content"]["1"]["quiz"]["wrongAnswer2"]}
                        wrongAnswer3={this.props.step["content"]["1"]["quiz"]["wrongAnswer3"]}
                        explanationBA={this.props.step["content"]["1"]["quiz"]["explanationWrongAnswer"]}
                        explanationGA={this.props.step["content"]["1"]["quiz"]["explanationGoodAnswer"]}
                        typeQ={this.props.step["content"]["1"]["quiz"]["mascotteQuestion"]["type"]}
                        fileQ={this.props.step["content"]["1"]["quiz"]["mascotteQuestion"]["file"]}
                        orientationQ={this.props.step["content"]["1"]["quiz"]["mascotteQuestion"]["orientation"]}
                        typeGA={this.props.step["content"]["1"]["quiz"]["mascotteGoodAnswer"]["type"]}
                        fileGA={this.props.step["content"]["1"]["quiz"]["mascotteGoodAnswer"]["file"]}
                        orientationGA={this.props.step["content"]["1"]["quiz"]["mascotteGoodAnswer"]["orientation"]}
                        typeWA={this.props.step["content"]["1"]["quiz"]["mascotteWrongAnswer"] ? this.props.step["content"]["1"]["quiz"]["mascotteWrongAnswer"]["type"] : null}
                        fileWA={this.props.step["content"]["1"]["quiz"]["mascotteWrongAnswer"] ? this.props.step["content"]["1"]["quiz"]["mascotteWrongAnswer"]["file"] : null}
                        orientationWA={this.props.step["content"]["1"]["quiz"]["mascotteWrongAnswer"] ? this.props.step["content"]["1"]["quiz"]["mascotteWrongAnswer"]["orientation"] : null}
                        nbStep={this.props.step["order"]}
                        hasAlreadySucceeded={this.props.step["content"]["1"]["quiz"]['already']}
                        bonusXp={this.props.step["content"]["1"]["quiz"]['bonusXp']}
                        setShowNext={this.props.setShowNext}
                        setSuccess={this.props.setSuccess}
                        actionNext={this.props.actionNext}
                        addXp={this.props.addXp}
                        expandStar={this.props.expandStar}
                    />
                </div>
            )
        }

        if(this.props.step["screen"]["1"]["type"] === "partxp") {
            this.fixedScreen = 2
            return (
                <div className={style.container}>
                    <StarRainComponent
                        ref={(node) => {this.fixedScreenRef = node}}
                        nbXp={this.props.step["content"]["1"]["partxp"]["xp"]}
                        hasAlready={this.props.step["content"]["1"]["partxp"]["already"]}
                        mascotteFirst={this.props.step["content"]["1"]["partxp"]["mascotte"]}
                        dialogueFirst={this.props.step["content"]["1"]["partxp"]["dialogue"]}
                        mascotteNext={this.props.step["content"]["1"]["partxp"]["mascotte2"]}
                        dialogueNext={this.props.step["content"]["1"]["partxp"]["dialogue2"]}
                        focused={this.stepFocus === this.props.step["order"]}
                        nbStep={this.props.step["order"]}
                        setShowNext={this.props.setShowNext}
                        addXp={this.props.addXp}
                        expandStar={this.props.expandStar}
                    />
                </div>
            )
        }

        if(this.props.step["screen"]["1"]["type"] === "materials") {
            this.fixedScreen = 3
            return (
                <div className={style.container} style={{borderTopLeftRadius: "40px", borderTopRightRadius: "40px", overflow: "hidden"}}>
                    <MaterialComponent
                        ref={(node) => {this.fixedScreenRef = node}}
                        materials={this.props.step["content"]["1"]["materials"]["icons"]}
                        type={this.props.step["content"]["1"]["materials"]["mascotte"]["type"]}
                        file={this.props.step["content"]["1"]["materials"]["mascotte"]["file"]}
                        orientation={this.props.step["content"]["1"]["materials"]["mascotte"]["orientation"]}
                        dialogue={this.props.step["content"]["1"]["materials"]["dialogue"]}
                        nbStep={this.props.step["order"]}
                        setShowNext={this.props.setShowNext}
                    />
                </div>
            )
        }

        return (
            <div className={style.container} style={{borderTopLeftRadius: "40px", borderTopRightRadius: "40px", overflow: "hidden"}}>

                {this.showItems()}

            </div>
        )
    }
}

export default ScreenTemplate;
